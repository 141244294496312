
import {ModalComponent} from './modal';

export const modal = (component: () => React.ReactNode) => (() => {
    

    return (
        <>
            {component()}

            <ModalComponent />
        </>
    );
});
