const JackpotABI = [
    {
        inputs: [],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
] as const;

export default JackpotABI