const GameABI =[
    {
        inputs: [
            {
                internalType: "uint256",
                name: "gameId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "expectedRound",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "referrer",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "roundPk",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "voteData",
                type: "bytes",
            },
            {
                internalType: "bytes",
                name: "signature",
                type: "bytes",
            },
        ],
        name: "placeBet",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },

{
        inputs: [
            {
                internalType: "uint256",
                name: "gameId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "roundId",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "expectedValue",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "expectedUserValue",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "sk",
                type: "uint256",
            },
        ],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
] as const;

export default GameABI;