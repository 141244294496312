import {ApolloClient, split, HttpLink, InMemoryCache} from "@apollo/client";
import {grapthQLServer, WSgrapthQLServer} from "shared/config/variables";
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from "@apollo/client/link/ws";


const httpLinkGrapthQL = new HttpLink({
    uri: grapthQLServer
});


const wsLinkGrapthQL = new WebSocketLink({
    uri: WSgrapthQLServer,
    options: {
        reconnect: true,
    },
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLinkGrapthQL,
    httpLinkGrapthQL,
);

export const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
});





