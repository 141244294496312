import {init} from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {SENTRY_KEY} from 'shared/config/variables'

export const sentry = (component: () => React.ReactNode) => () => {
    init({
        dsn: SENTRY_KEY,
        integrations: [new BrowserTracing()],

        tracesSampleRate: 1.0,
    });

    return component();
}
