
import styled from 'styled-components';

export const ModalLayout = styled.div`
  position: fixed;
  z-index: 10;
  top:0;
  left:0;

  width:100%;
  height: 100%;


  background: rgba(32, 40, 64, 0.4);
  backdrop-filter: blur(4px);

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalDelete = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 20px;
  width: 100%;
  height: 100%;
`