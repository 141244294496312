
import {useEffect} from "react";
import ky from "ky";

import {
    contractsConfigApi,
    server
} from "shared/config/variables";

import {contractType} from "./type";
import GameABI from "./abi/GameABI";
import JackpotABI from "./abi/JackpotABI";
import {bsc, bscTestnet} from "@wagmi/chains";
import {LazyLayout} from "shared/ui/Loading";

const getEnvVar = (key: string) => {
    /*@ts-ignore*/
    const env = import.meta.env[key]

    if (env === undefined) {
        throw new Error(`Env variable ${key} is required`);
    }

    return env || "";
};

export let chainId = getEnvVar("VITE_CHAIN_ID");
export const rpc = chainId === '97' ? getEnvVar("VITE_TESTNET_RPC") : getEnvVar("VITE_BSC_RPC");

export const currentChain = chainId !== '97' ? bsc : bscTestnet

export const GameContract:contractType = {
    address: '0xCe15B7642915f41fc7f299166C13bb80D35f967A',
    abi: GameABI,
}

export const JackpotContract:contractType = {
    address: "0xE7057C3742C5929174e8aF4C75027419Fd0fc369",
    abi: JackpotABI,
}

export const ReferralRegistryContract:contractType = {
    address: "0x5ff1C852d7567233C3801364601CfA7CAcf8803c",
    abi: [],
}


const useInitConfig = () => {

    useEffect(() => {
        const getConfig = async () => {
            let getRequest:any = await ky(`${server}${contractsConfigApi}`).json();
            getRequest = getRequest.contracts.filter((contract: {chainId: string}) => contract.chainId == chainId)[0];
            if(!getRequest){
                console.error(`Config not found for chainId ${chainId}`);
                return;
            }

            GameContract.address = getRequest.addresses.GameWhiteRed;
            JackpotContract.address = getRequest.addresses.Jackpot;
            ReferralRegistryContract.address = getRequest.addresses.ReferralRegistry;
            chainId = getRequest.chainId;
        }

        getConfig();
    }, [])
}

export const initWeb3Config = (component: () => React.ReactNode) => (() => {
    useInitConfig();

    return <LazyLayout>{component()}</LazyLayout>;
})