
import {Suspense, ReactNode, memo} from "react";
import { ApolloProvider } from '@apollo/client';

import {client} from "shared/config/appolo";

export const withApollo = (component: () => ReactNode) => (() => (
    <ApolloProvider client={client} >
        <Suspense fallback="Loading...">{component()}</Suspense>
    </ApolloProvider>
));
