import { Suspense } from "react";
import { WagmiConfig } from "wagmi";
import { Web3Modal } from "@web3modal/react";

import {
  ethereumClient,
  projectId,
  wagmiClient,
} from "shared/config/blockChain";

export const withWagmi = (component: () => React.ReactNode) => () =>
  (
    <>
      <WagmiConfig client={wagmiClient}>
        <Suspense fallback="Loading...">{component()}</Suspense>
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
