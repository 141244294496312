import { useStore } from 'effector-react';

import { ModalDelete, ModalLayout } from './styled';
import {modalClose, modalStore} from 'shared/lib/modal';


export const ModalComponent = () => {
    const modalState = useStore(modalStore.$modal);

    const deleteModal = () => {
        modalClose(modalState?.dataProps.connectWallet)();
    }

    return(
        <>
            {modalState && modalState.ModalElement && (
                <ModalLayout>
                    <modalState.ModalElement {...modalState.dataProps} />

                    <ModalDelete
                        onClick={deleteModal}
                    />
                </ModalLayout>
            )}
        </>
    )
}