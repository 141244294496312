import {
  AppRoutes,
  cookieRoute,
  disclaimerRoute,
  touRoute,
} from "shared/config/routeConfig";

export const linksRulesPlatform = [
  {
    title: "Terms of use",
    link: touRoute,
  },

  {
    title: "Disclaimer",
    link: disclaimerRoute,
  },

  {
    title: "Cookie Policy",
    link: cookieRoute,
  },
];
