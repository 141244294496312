import { chainId, rpc } from "shared/config/blockChain";

export const getEnvVar = (key: string) => {
  /*@ts-ignore*/
  const env = import.meta.env[key];
  if (env === undefined) {
      throw new Error(`Env variable ${key} is required`);
  }

  return env || "";
};

export const server = getEnvVar("VITE_SERVER");

export const NODE_ENV = getEnvVar("MODE");
export const SENTRY_KEY = getEnvVar("VITE_SENTRY_KEY");

export const isProdEnv = NODE_ENV === "production";

const grapthQl = getEnvVar("VITE_GRAPTH");
const mode = isProdEnv ? "prod" : "dev";

export const ADDRESS_MOCK = "0xbcd4042de499d14e55001ccbb24a551f3b954096";

export const grapthQLServer = `https${grapthQl}${mode}`;
export const WSgrapthQLServer = `wss${grapthQl}${mode}`;

export const root = document.querySelectorAll("#root")[0];

export * from "./api";
export { chainId, rpc };