import { configureChains, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { LedgerConnector } from "wagmi/connectors/ledger";
import { InjectedConnector } from "wagmi/connectors/injected";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";

import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { rpc } from "../variables/index";
import { currentChain } from "./contracts";

export const getEnvVar = (key: string) => {
  /*@ts-ignore*/
  const env = import.meta.env[key];

  if (env === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }

  return env || "";
};

export const projectId = getEnvVar("VITE_PROJECT_ID");

export const { chains, provider, webSocketProvider } = configureChains(
  [currentChain],
  [
    w3mProvider({ projectId }),
    publicProvider(),
    jsonRpcProvider({
      rpc: (_) => ({
        http: rpc,
      }),
      stallTimeout: 1000,
    }),
  ]
);

export const connectorWC = new WalletConnectConnector({
  chains,
  options: {
    projectId,
    metadata: {
      name: "WhiteRed",
      description: "The project WhiteRed",
      url: "https://dev.whitered.io/login",
      icons: [],
    },
    //CHANGED
    showQrModal: true,
  },
});

const injectedConnect = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: true,
  },
});

export const connectorMetamask = new MetaMaskConnector({
  chains,
});

const connectorLedger = new LedgerConnector({
  chains: chains,
  options: {
    rpc: {
      1: rpc,
    },
  },
});

//CHANGED
const walletConnect = w3mConnectors({ projectId, version: 2, chains });

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    injectedConnect,
    connectorMetamask,
    connectorLedger,
    walletConnect[0],
  ],
  provider,
  webSocketProvider,
});

export const ethereumClient = new EthereumClient(wagmiClient, chains);